window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
    window.triggerLead();
});

window.addEventListener('heyflow-init', async (event) => {
    window._udata= {
        n:"FR"
        };
    try {
        document.querySelector('input[data-label="aff_sub8"]').value = "variant A"
    } catch(e) {}
    zipcodeAutocomplete();
    await setIp();
    isMobileDevice();
    setPageUrl();
    popup();
});



function setPageUrl() {
    const pageUrlInput = document.querySelector('input[data-label="page-url"]')
    pageUrlInput.value = window.location.href;
}

function zipcodeAutocomplete() {
    console.log("zipcode")

    let zipcodes = {};
    let startZip = "xxxxxx";

    const zipcode_input = document.querySelector('input[data-label="zipcode"]');
    const city_input = document.querySelector('select[data-label="city"]');
    const drop_div = document.createElement('div');

    function myFunctionFocusOut(e) {
        if (drop_div.classList.contains("show")) {
            document.getElementById("myDropdown").classList.toggle("show")
            drop_div.style.zIndex = -10
        }
        if (e.relatedTarget && e.relatedTarget.dataset.zipcode) {
            city_input.innerHTML = zipcodes[e.relatedTarget.dataset.zipcode].cities.map(valeur => `<option value="${valeur}">${valeur}</option>`).join("")
            city_input.value = zipcodes[e.relatedTarget.dataset.zipcode].cities[0]
            e.target.value = e.relatedTarget.dataset.zipcode;
            requestAnimationFrame(() => {
                e.target.parentNode.parentNode.parentNode.classList.remove("error");
            })
        }
        if (!zipcodes[e.target.value]) {
            zipcode_input.value = ""
            city_input.value = ""
            e.target.parentNode.parentNode.parentNode.classList.remove("error");
        }
    }

    function filterFunction(e) {
        if (e.target.value.length >= 3 && !drop_div.classList.contains("show")) {
            getData()
            drop_div.classList.toggle("show")
            drop_div.style.zIndex = 100
        } else if (e.target.value.length < 3 && drop_div.classList.contains("show")) {
            drop_div.classList.toggle("show")
            drop_div.style.zIndex = -10
            city_input.value = ""
        } else if (e.target.value.length >= 3) {
            getData()
        }
        if (zipcodes[e.target.value]) {
            // Code valide
            city_input.innerHTML = zipcodes[e.target.value].cities.map(valeur => `<option value="${valeur}">${valeur}</option>`).join("")
            city_input.value = zipcodes[e.target.value].cities[0]
            if (drop_div.classList.contains("show")) {
                document.getElementById("myDropdown").classList.toggle("show")
                drop_div.style.zIndex = -10
            }
        } else {
            city_input.value = ""
        }
    }

    async function getData() {
        if (!zipcode_input.value.startsWith(startZip)) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            startZip = zipcode_input.value

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    "zipcode": startZip,
                    "countryCode": "FR"
                }),
                redirect: 'follow'
            };

            let res = await fetch("https://sweepapi.vertigodata.com/verticals_api/zipcode/nfSYfkNH7seSdBuB", requestOptions)
            zipcodes = await res.json();
            zipcode_input.setAttribute("pattern", "(" + Object.keys(zipcodes).join('|') + ")")
        }

        //let dataArr = zipcodes.filter(zip => zip.startsWith(zipcode_input.value));
        let dataArr = Object.keys(zipcodes)
        .filter(cle => cle.startsWith(zipcode_input.value))
        .reduce((obj, cle) => {
            obj[cle] = zipcodes[cle];
            return obj;
        }, {});
        console.log(dataArr)
        cleanData()
        for (const zipcode in dataArr) {
            let a = document.createElement('a');
            a.setAttribute("tabindex", -1)
            a.setAttribute("href", '#')
            a.dataset.zipcode = zipcode;
            a.innerHTML = zipcode;
            drop_div.append(a)
        }
    }

    function cleanData() {
        drop_div.innerHTML = ''
    }

    drop_div.setAttribute("id", "myDropdown");
    drop_div.setAttribute("class", "dropdown-content");
    zipcode_input.setAttribute("autocomplete", "off")
    zipcode_input.setAttribute("maxLength", "5")

    //document.getElementsByClassName('help')[0].parentNode.insertBefore(drop_div, document.getElementsByClassName('help')[0])
    zipcode_input.parentNode.parentNode.after(drop_div);

    zipcode_input.addEventListener('focusout', myFunctionFocusOut)
    zipcode_input.addEventListener('keyup', filterFunction)

    city_input.readOnly = true;
}

async function setIp() {
    const ipInput = document.querySelector('input[data-label="ip"]');
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    let res = await fetch("https://europe-southwest1-react-lp-387513.cloudfunctions.net/ip_retain", requestOptions)
    let ip = await res.text()

    ipInput.value = ip;
}

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileInput = document.querySelector('input[data-label="desktopmobile"]');

    const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    if (mobile) {
        mobileInput.value = "mobile"
    } else {
        mobileInput.value = "desktop"
    }
}

function popup() {
    const popup = document.querySelector('div.inner-full.container-style-1');
    popup.style.position = "fixed";
    popup.style.top = "32px";
    popup.style.backgroundColor = "#fafafa";
    popup.style.zIndex = "100";
    const closePopup = popup.querySelector("a");
    const closePopupContainer = popup.querySelector('div[data-blocktype="link-list"]');
    closePopupContainer.style.alignSelf = "end";
    closePopup.style.paddingRight = "32px";
    closePopup.removeAttribute("href")
    closePopup.removeAttribute("target")

    //closePopup.addEventListener('click', (e) => {
    requestAnimationFrame(() => {
        const p = document.querySelector('div.inner-full.container-style-1');
        p.style.position = "";
        p.style.top = "";
        p.style.backgroundColor = "";
        closePopup.parentNode.parentNode.parentNode.style.display = "none";
        p.classList.remove("container-style-1");
    })
}